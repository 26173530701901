<template>
  <el-form
    ref="commonForm"
    :model="commonForm"
    :rules="rules"
    class="commonForm"
    :label-width="abroad ? '250px' : '200px'"
  >
    <el-form-item
      :label="autoPrefix('2573f3a', 'lab')"
      prop="organizationType"
    >
      <el-radio-group v-model="commonForm.organizationType">
        <el-radio
          v-for="(item, index) of organizationTypeOptions"
          :key="index"
          :label="item.key"
        >
          {{ autoPrefix(item.name) }}
        </el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item :label="autoPrefix('fafa54b', 'lab')" prop="name">
      <el-input
        v-model="commonForm.name"
        :placeholder="autoPrefix('520f308')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('a27362a')" prop="legalPerson">
      <el-input
        v-model="commonForm.legalPerson"
        :placeholder="autoPrefix('94d558b')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('af12ab4')" prop="idCard">
      <el-input
        v-model="commonForm.idCard"
        :placeholder="autoPrefix('8f5073c')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('af975db')" prop="creditCode">
      <el-input
        v-model="commonForm.creditCode"
        :placeholder="autoPrefix('1941316', 'pla')"
      />
    </el-form-item>
    <div v-if="!abroad" class="provinceContainer">
      <el-form-item :label="autoPrefix('da5ea34')" prop="province">
        <el-select
          v-model="commonForm.province"
          :placeholder="autoPrefix('8c7aeca')"
          @change="changeProvince"
        >
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="city" label-width="0">
        <el-select
          v-model="commonForm.city"
          :placeholder="autoPrefix('8c7aeca')"
        >
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </div>
    <el-form-item :label="autoPrefix('95c9900')" prop="address">
      <el-input
        v-model="commonForm.address"
        :placeholder="autoPrefix('ce9381f')"
      />
    </el-form-item>
    <el-form-item :label="autoPrefix('1f832ea')" prop="establishedTime">
      <el-date-picker
        v-model="commonForm.establishedTime"
        type="date"
        value-format="timestamp"
        :placeholder="autoPrefix('8c7aeca', 'pla')"
        :picker-options="pickerOptions"
      />
    </el-form-item>
    <el-form-item>
      <el-button
        type="primary"
        class="loginButton"
        @click="submitForm('commonForm')"
      >
        {{ autoPrefix('127e973') }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
// import { validateMobilePhone } from '@/utils/helper'
import validator, { imageMimeType } from '@/utils/validation'
import API from '@/api'
import { log } from '@/utils/helper'
import { ORGANIZATION_TYPE } from '@/utils/constants'
const i18nPrefix = {
  label: 'account.register.organizationForm.lable.',
  pla: 'account.register.organizationForm.pla.',
  com: 'account.register.com.',
}
import { getToken } from '@/utils/auth'
export default {
  name: 'OrganizationForm',
  props: {
    registerType: {
      type: String,
      default: 'PERSON',
    },
  },
  // components: {},
  data() {
    return {
      pickerOptions: {
        disabledDate(date) {
          const today = new Date()
          today.setHours(23)
          return date > today
        },
      },
      headers: {
        accessToken: getToken(),
      },
      imageMimeType,
      shortTips: '获取验证码',
      graphicalIndex: 0,
      organizationTypeOptions: ORGANIZATION_TYPE,
      commonForm: {
        organizationType: '',
        name: '',
        legalPerson: '',
        idCard: '',
        creditCode: '',
        province: '',
        city: '',
        address: '',
        establishedTime: '',
      },
      provinceList: [],
      cityList: [],
    }
  },
  computed: {
    abroad() {
      return this.$route.query.abroad === 'abroad'
    },
    rules() {
      return {
        organizationType: [
          {
            required: true,
            message: this.autoPrefix('520f308'),
          },
        ],
        name: [
          { required: true, message: this.autoPrefix('520f308') },
          { validator: validator.organizationName },
        ],
        legalPerson: [
          { required: true, message: this.autoPrefix('94d558b') },
          { validator: validator.legalPerson },
        ],
        idCard: [
          { required: true, message: this.autoPrefix('8f5073c') },
          { validator: validator.idNumber('d2ac6f8') },
        ],
        creditCode: [
          { required: true, message: this.autoPrefix('1941316') },
          { validator: validator.creditCode_zh },
        ],
        province: [
          { required: true, message: this.autoPrefix('8c7aeca') },
        ],
        city: [{ required: true, message: this.autoPrefix('8c7aeca') }],
        address: [
          { required: true, message: this.autoPrefix('ce9381f') },
          { validator: validator.organizationAddress },
        ],
        establishedTime: [
          {
            required: true,
            message: this.autoPrefix('4d90100'),
          },
        ],
      }
    },
  },
  created() {
    this.handleTest()
    this.getAreaTree()
  },
  methods: {
    handleTest() {
      if (window.location.host === 'localhost:8080') {
        this.commonForm = {
          organizationType: 'LAW_OFFICE',
          name: '黄宏阿打算阿达爽啊蒜氨酸懂爱的',
          legalPerson: '黄宏阿打算阿达爽啊蒜氨酸懂爱的',
          idCard: '500224199900000000',
          creditCode: '600224199900000000',
          province: '',
          city: '',
          address: '黄宏阿打算阿达爽啊蒜氨酸懂爱的',
          establishedTime: '1610467200000',
        }
      }
    },
    creatData() {
      const form = this.commonForm
      const data = {
        organizationType: form.organizationType,
        name: form.name,
        legalPerson: form.legalPerson,
        idCard: form.idCard,
        creditCode: form.creditCode,
        province: form.province,
        city: form.city,
        address: form.address,
        establishedTime: form.establishedTime,
      }
      return data
    },
    changeProvince(val) {
      this.commonForm.city = ''
      if (val) {
        for (let index = 0; index < this.provinceList.length; index++) {
          const element = this.provinceList[index]
          if (element.id === val) {
            this.cityList = element.children
            break
          }
        }
      }
    },
    getAreaTree() {
      API.common
        .getAreaTree()
        .then((res) => {
          if (res && res.content) {
            this.provinceList = res.content
          }
        })
        .catch(() => {
          log('Interface exception API.common.getAreaTree()')
        })
    },
    autoPrefix(name) {
      let fun = '$t'
      if (this.abroad) {
        fun = 'geEn'
      }
      return this[fun](name)
    },
    geEn(key) {
      const i18n = this.$i18n
      return i18n._getMessages().en[key] || key
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('nextStep')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // resetForm() {
    //   this.$refs['commonForm'].resetFields()
    // },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
// @import '~@/styles/common.less';
.commonForm {
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  & /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  & /deep/ .loginButton {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 360px;
    height: 40px;
    background: #00a4ff;
    border-radius: 4px;
  }
  & /deep/ .el-input__inner {
    // border-radius: 23px;
  }

  & /deep/ .el-cascader,
  .el-select,
  .el-date-editor {
    width: 100%;
  }
  & /deep/ .el-form-item__content {
    width: 360px;
  }
  .uploadBusinessLicense {
    & /deep/ .el-button {
      margin-right: 20px;
    }
    .el-upload__tip {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .provinceContainer {
    display: flex;
    & /deep/ .el-form-item__content {
      width: 180px;
    }
  }
  .graphical {
    position: relative;
    .uiImg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 80px;
      height: 34px;
      padding: 4px 0 2px 10px;
      margin-right: 10px;
      border-left: 2px solid #efefef;
      cursor: pointer;
    }
  }
  .short {
    position: relative;
    .uiText {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 70px;
      padding: 0 10px;
      border-left: 2px solid #efefef;
      font-size: 14px;
      color: #cccccc;
      text-align: center;
      cursor: pointer;
    }
  }

  .agreement {
    & /deep/ .el-checkbox__label {
      display: inline-grid;
      white-space: pre-line;
      word-wrap: break-word;
      overflow: hidden;
      line-height: 20px;
    }
  }
}
</style>
