<template>
  <div class="register">
    <Title :title="title" class="title" />
    <Steps :active="stepsActive" :step1="step1" />
    <OrganizationForm
      v-show="stepsActive === 0"
      ref="form1"
      class="form"
      :steps-active="stepsActive"
      @nextStep="nextStep"
    />
    <CommonForm
      v-show="stepsActive === 1"
      ref="form2"
      class="form"
      :register-type="registerType"
      @lastStep="lastStep"
      @submitForm="submitForm"
    />

    <Success v-show="stepsActive === 2" />
  </div>
</template>

<script>
import Steps from '@/components/Steps'
import OrganizationForm from '../components/OrganizationForm'
import CommonForm from '../components/CommonForm'
import Success from '../components/Success'

// import ConditionSelection from '@/components/ConditionSelection'
// import FirmCard from '@/components/FirmCard'
import Title from '@/components/Title'
import { mapGetters } from 'vuex'
import API from '@/api'
import { log } from '@/utils/helper'
export default {
  name: 'Organization',
  components: {
    Title,
    Steps,
    OrganizationForm,
    CommonForm,
    Success,
  },
  data() {
    return {
      step1: 'c31ec5a',
      title: '机构注册',
      registerType: 'Organization',
      stepsActive: 0,
    }
  },
  computed: {
    ...mapGetters(['test']),
  },
  watch: {
    stepsActive(newVal) {
      console.log('newVal', newVal)
      if (newVal === 2) {
        this.goLogin()
      }
    },
  },
  created() {},
  methods: {
    goLogin() {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId)
        this.$router.push({
          path: '/account/login',
        })
      }, 3000)
    },
    submitForm() {
      const data1 = this.$refs.form1.creatData()
      const data2 = this.$refs.form2.creatData()
      const data = Object.assign(data1, data2)
      console.log('data', data)
      this.register(data)
    },
    lastStep() {
      this.changeStepsActive(0)
    },
    nextStep() {
      this.changeStepsActive(1)
    },
    changeStepsActive(index) {
      this.stepsActive = index
    },
    register(data) {
      API.account
        .organizationRegister(data)
        .then((res) => {
          if (res.code === '000000') {
            this.stepsActive = 2
            this.$message.success('注册成功')
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(() => {
          log('Interface exception API.account.userRegister()')
        })
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.register {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: @uni-width;
  margin: 20px auto;
  min-height: calc(100vh - 260px);
  background: #fff;
  .title {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 40px;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
