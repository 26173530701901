<template>
  <div class="success">注册成功，3秒后跳转到登录页面</div>
</template>

<script>
// import Steps from './Steps'
export default {
  name: 'Success',
  components: {
    // Steps,
  },
  data() {
    return {
      stepsActive: 0,
    }
  },
  methods: {
    goLogin() {
      this.$router.push({
        path: '/account/login',
      })
    },
    register(type) {
      this.$emit('changeRegisterType', type)
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
// @import '~@/styles/common.less';
.success {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  &::before {
    content: '';
    position: absolute;
    top: -96px;
    width: 72px;
    height: 72px;
    background: url('~@/assets/account/success.png');
    background-size: 72px 72px;
  }
}
</style>
